window.$ = window.jQuery = require('jquery');
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'

import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js'


import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');
Vue.prototype.moment = moment;


import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
Vue.use(Skeleton)


import * as config from "./config";
Vue.prototype.$cg = config;


import AOS from 'aos'
import 'aos/dist/aos.css'



import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
window.toastr = require('toastr')
Vue.use(VueToastr2)

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-left",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}



import VeeValidate from 'vee-validate';
import { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es.js";
import en from "vee-validate/dist/locale/en.js";
Vue.use(VeeValidate);


console.log("- Idioma del navegador - - " , navigator.language);


import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import { translations } from "./i18n/translations.js";
const messages = translations;
if (navigator.appName == "Netscape") {
    var lenguage = navigator.language.toLowerCase();
} else {
    var lenguage = navigator.browserLanguage;
}
var lenguage2 = lenguage;
var locale = "pt-PT";
var Generallenguage = lenguage.substr(0, 2)

Validator.localize("pt", es);


if (Generallenguage == "en") {
    locale = "en";
    Validator.localize("en", en);
} else if (Generallenguage == "es") {
    if (lenguage2 == "es-419" || lenguage2 == "es-la") {
        locale = "co";
    }
    else if (lenguage2 == "es-VE" || lenguage2 == "es-VE") {
        locale = "ve"; // Venezuela
    }
    
     else {
        locale = "es";
    }
} else if(Generallenguage == "pt"){
        locale = "pt"; // Portugal
        Validator.localize("pt");
} else {
    locale = "en";
    Validator.localize("en", en);
}

const storedLanguage = localStorage.getItem("appLanguage") || locale;
// store.state.idioma = storedLanguage || locale;


const i18n = new VueI18n({
    locale: storedLanguage || locale, // set locale
    messages, // set locale messages
});


Vue.prototype.$language = i18n; // Agrega la instancia de VueI18n a Vue.prototype


import * as fn from "./config/funtionsglobal";
Vue.prototype.$funtion = fn;




Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')