<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalLenguaje"
    style="text-align: left"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" style="padding: 28px">
        <div class="modal-header" style="align-items: center">
          <img src="/marca/panda9.svg" alt="" style="width: 100px" />
          <h4 style="font-size: 23px; color: #000">Idioma y región</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="margin-top: -154px"
          >
            <i class="far fa-times" style="font-size: 20px"></i>
          </button>
        </div>
        <div class="modal-body">
          <strong>Elige un idioma y una región</strong>
          <div class="row" style="margin-top: 20px">
            <div class="col-12 col-lg-3" style="margin-bottom: 15px">
              <a
                href="https://www.qrcheckin.es/"
                style="text-decoration: none; color: #333"
              >
                <div
                  class="slectIdioma card"
                  :style="idioma == 'es' ? { color: '#3F51B5', 'font-weight': 700 } : {}"
                  
                  style="padding: 16px; font-weight: 600; font-size: 14px"
                >
                  <img src="/espana.png" alt="" style="width: 40px" /> España
                </div>
              </a>
            </div>
            <div class="col-12 col-lg-3" style="margin-bottom: 15px">
              <div
                class="slectIdioma card"
                :style="idioma == 'co' ? { color: '#3F51B5', 'font-weight': 700 } : {}"
                @click.prevent="changeLanguage('co')"
                style="padding: 16px; font-weight: 600; font-size: 14px"
              >
                <img src="/colombia.png" alt="" style="width: 40px" /> Colombia
              </div>
            </div>
            <div class="col-12 col-lg-3" style="margin-bottom: 15px">
              <div
                class="slectIdioma card"
                @click.prevent="changeLanguage('pt')"
                :style="idioma == 'pt' ? { color: '#3F51B5', 'font-weight': 700 } : {}"
                style="padding: 16px; font-weight: 600; font-size: 14px"
              >
                <img src="/portugal.png" alt="" style="width: 40px" /> Portugal
              </div>
            </div>

            <div class="col-12 col-lg-3" style="margin-bottom: 15px">
              <div
                class="slectIdioma card"
                :style="idioma == 'en' ? { color: '#3F51B5', 'font-weight': 700 } : {}"
                @click.prevent="changeLanguage('en')"
                style="padding: 16px; font-weight: 600; font-size: 14px"
              >
                <img src="/uuee.png" alt="" style="width: 40px" />
                Estados unidos
              </div>
            </div>

            <div class="col-12 col-lg-3" style="margin-bottom: 15px">
              <div
                class="slectIdioma card"
                :style="idioma == 've' ? { color: '#3F51B5', 'font-weight': 700 } : {}"
                @click.prevent="changeLanguage('ve')"
                style="padding: 16px; font-weight: 600; font-size: 14px"
              >
                <img src="/venezuela.png" alt="" style="width: 40px" />
                Venezuela
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.idioma = localStorage.getItem("appLanguage") || "es";
  },
  data() {
    return {
      i18n: null,
      idioma: "",
    };
  },
  components: {},
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("appLanguage", lang); // Guarda el idioma seleccionado en localStorage
      this.idioma = lang;
      $("#modalLenguaje").modal("hide");
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.slectIdioma {
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:hover {
    background: #eeeeeec1;
    transition: 0.4s;
  }
}
</style>
