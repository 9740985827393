<template>
  <!-- Row1 -->
  <div class="container bloque1 p-0">
    <div class="row" style="">
      <div class="col-12 col-lg-6 dos" style="text-align: left">
        <div class="xs" style="margin-top: 46px">
          <img
            style="width: 21%"
            v-animate-css="'zoomIn'"
            class="adorno2 img-fluid"
            src="/panda4.svg"
          />
        </div>
        <h1
          v-animate-css="'slideInLeft'"
          class="xs subtitle"
          style="
            padding-top: 0 !important;
            margin-top: -18px;
            font-family: 'Worldwise Sans' !important;
            font-weight: bold;
            font-style: normal;
            background: linear-gradient(to bottom right, #113c11, #4caf50, #113c11);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          "
        >
          {{ $t("home.p2") }}
        </h1>

        <h1
          v-animate-css="'slideInLeft'"
          class="mt-3 lg subtitle"
          style="
            font-size: 60px;
            font-family: 'Worldwise Sans' !important;
            font-weight: bold;
            font-style: normal;
            background: linear-gradient(to bottom right, #113c11, #4caf50, #113c11);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          "
        >
          {{ $t("home.p1") }}
        </h1>
        <!-- 
        <div class="TextSubtitle centerxs" style="font-size: 23px; margin-top: -15px">
          {{ $t("home.p3") }} <img src="/whatsapp.png" alt="" style="width: 30px" />
        </div> -->

        <div class="xs">
          <div class="bloqueQR" style="">
            <!-- <img
              style="width: 38%; margin-top: 29px"
              v-animate-css="'zoomIn'"
              class="adorno2 img-fluid"
              src="/panda4.svg"
            />

            <br /> -->

            <div class="submensaje" style="width: 80%; margin: auto">
              {{ $t("home.p4") }}
            </div>
          </div>
        </div>

        <div style="width: 73%; margin-top: 36px; min-height: 100px" class="lg">
          <transition
            name="texto-transition"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <div :key="currentKey" class="texto">
              {{ currentText }}<span v-if="currentIndex === 2">&nbsp;</span>
            </div>
          </transition>
          <div class="complemento ml-1">{{ $t("home.p64") }}</div>
          <div class="complemento ml-1">{{ $t("home.p65") }}</div>
        </div>
        <div class="otro-texto mt-3 lg" style="width: 65%">
          {{ $t("home.p5") }}
          <img src="/whatsapp.png" alt="" style="width: 30px" />
        </div>

        <br />

        <div class="divListaFunctions">
          <ul class="p0">
            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> {{ $t("home.p66") }}
              </div>
            </li>
            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> {{ $t("home.p67") }}
              </div>
            </li>
            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> {{ $t("home.p68") }}
              </div>
            </li>
            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> {{ $t("home.p69") }}
              </div>
            </li>

            <!-- <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> Contratos personalizados
              </div>
            </li> -->

            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> {{ $t("home.p70") }}
              </div>
            </li>

            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> {{ $t("home.p71") }}
                <img src="/logoreview.svg" alt="" style="width: 74px" />
              </div>
            </li>

            <!-- 
            <li>
              <div>
                <ion-icon name="checkmark-circle"></ion-icon> Verificación de identidad
              </div>
            </li> -->
          </ul>
        </div>

        <div
          class="botones d-flex"
          v-animate-css="'slideInUp'"
          style="text-align: center; margin: auto; width: 100%"
        >
          <div>
            <a class="boton-registrate" href="https://app.qrcheckin.es/register" style="">
              <button
                class="btn"
                :style="{ background: $cg.color.colorOne }"
                style="
                  border-radius: 10px;
                  color: white;
                  font-weight: 700;
                  font-size: 16px;
                  max-block-size: 56px;
                  box-shadow: #1f962382 0px 10px 25px;
                  --shadow-color: var(
                    --button-shadow-color,
                    var(--highlight-shadow, var(--color-shadow))
                  );
                  --shadow-size: var(
                    --button-shadow-from,
                    var(--shadow-button, 0 10px 25px)
                  );
                  line-height: 24px;
                  transition: color 0s ease 0s, background-color 0.15s ease-in-out 0s;
                  padding: 16px 50px;
                  background: rgb(17, 177, 77);
                "
              >
                {{ $t("home.p72") }}
              </button>
            </a>
          </div>
          <div>
            <button
              id="miBoton"
              class="btn"
              :style="{ 'border-color': $cg.color.colorOne, color: $cg.color.colorOne }"
              style="
                margin-left: 25px;
                border-radius: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                transition: color 0s ease 0s, background-color 0.15s ease-in-out 0s;
                padding: 13px 19px;
                border-color: rgb(255 255 255);
                color: rgb(17, 177, 77);
                background: white;
              "
            >
              {{ $t("home.p8") }} <img src="/calendar.png" alt="" style="width: 30px" />
            </button>
          </div>
        </div>

        <br />

        <!-- <div class="bloqueNuevo" v-animate-css="'slideInLeft'">
          <br />
          <strong>PROCESOS DE DEMOSTRACIÓN</strong>
          <br />
          <button
            id="miBoton"
            data-toggle="modal"
            data-target="#modalQR"
            class="btn"
            style="
              margin-top: 10px;
              border-radius: 10px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              transition: color 0s ease 0s, background-color 0.15s ease-in-out 0s;
              background: #ffc107;
              color: white;
              box-shadow: 0px 0px 2px 2px #eee;
            "
          >
            Check-In por QR <i class="fas fa-qrcode" style="color: #333"></i>
          </button>

          <button
            data-toggle="modal"
            data-target="#modalWhatsapp"
            id="miBoton"
            class="btn"
            style="
              margin-top: 10px;
              border-radius: 10px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              transition: color 0s ease 0s, background-color 0.15s ease-in-out 0s;
              background: #ffc107;
              color: white;
              box-shadow: 0px 0px 2px 2px #eee;
            "
          >
            Check-In por WhatsApp <img src="/whatsapp.png" alt="" style="width: 20px" />
          </button>
        </div> -->
      </div>

      <div class="col-12 col-lg-6 lg" style="padding-left: 60px">
        <div id="mi-div">
          <img
            src="/finalLanding.svg"
            style="width: 747px; margin-left: -190px !important"
            alt=""
          />
        </div>
      </div>
    </div>

    <BloquePublish />
  </div>
</template>

<script>
import BloquePublish from "./bloquePublish.vue";

export default {
  data() {
    return {
      palabras: [this.$t("home.p73"), this.$t("home.p74"), this.$t("home.p75")],
      currentIndex: 0,
      charIndex: 0,
      currentKey: 0,
      currentText: "",
    };
  },
  methods: {
    escribirTexto() {
      if (this.charIndex <= this.palabras[this.currentIndex].length) {
        this.currentText = this.palabras[this.currentIndex].substring(0, this.charIndex);
        this.charIndex++;
        setTimeout(this.escribirTexto, 100);
      } else {
        setTimeout(this.borrarTexto, 2000);
      }
    },
    borrarTexto() {
      if (this.charIndex >= 0) {
        this.currentText = this.palabras[this.currentIndex].substring(0, this.charIndex);
        this.charIndex--;
        setTimeout(this.borrarTexto, 50);
      } else {
        this.currentIndex = (this.currentIndex + 1) % this.palabras.length;
        this.currentKey += 1;
        setTimeout(this.escribirTexto, 1000);
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight; // Trigger reflow
      el.style.transition = "opacity 1s";
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = "opacity 1s";
      el.style.opacity = 0;
      done();
    },
  },
  mounted() {
    this.escribirTexto();
  },
  components: { BloquePublish },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .bloque1 {
    margin-top: 0% !important;
  }

  .dos {
    text-align: center !important;

    .subtitle {
      color: #333;
      text-transform: uppercase;
      // font-size: 60px;
      font-weight: 900;
      line-height: 52px;
      // font-size: 71px !important;
      line-height: 85px;
    }
  }

  .divListaFunctions {
    text-align: left !important;
    padding-left: 31px !important;
  }
  .botones {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 20px;

    #miBoton {
      margin-left: 0 !important;
    }
  }
}
.bloque1 {
  margin-top: 11%;
}
ul {
  padding: 0 !important;
  li {
    margin-bottom: 13px;
    font-size: 18px;
    font-weight: 500;
    color: #4a4a4a;
    .hydrated {
      color: #4caf50;
    }
  }
}
.texto-transition-enter-active,
.texto-transition-leave-active {
  transition: opacity 1s;
}
.texto-transition-enter,
.texto-transition-leave-to {
  opacity: 0;
}
.texto {
  font-size: 32px;
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  color: #000;
}
.complemento {
  font-size: 32px;

  display: inline-block;
  vertical-align: top;
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Puedes ajustar la distancia de movimiento aquí */
  }
}

#mi-div img {
  animation: moveUpDown 5s ease-in-out infinite; /* Puedes ajustar la duración aquí */
}
</style>
