<template>
  <div
    class="container-fluid footerDos d-flex flex-row"
    style="
      justify-content: center;
      background: rgb(255 255 255);
      height: 130px !important;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin-top: 0px;
    "
  >
    <!-- <div class="d-flex flex-column direccion" style="color: #000; text-align: left">
      <strong>BIG NERDS COMPANY LLC</strong>
      <p>USA, New York 238 Wilson Ave #1014, Brooklyn</p>
    </div> -->

    <!-- <div class="d-flex flex-column direccion" style="color: #000; text-align: left">
      <strong>BIG NERDS COMPANY LLC </strong>
      <p>B23780349 LA PARRILLA 50 23740 ANDUJAR (JAEN) ESPAÑA</p>
    </div> -->

    <div
      class="otrBloque d-flex flex-column"
      style="font-size: 14px; text-align: left; width: 65%"
    >
      <div class="contact" style="color: #000; text-align: right; margin-right: 66px">
        <strong style="cursor: pointer" @click.prevent="openModal()"
          ><i class="fas fa-envelope mr-2" style="color: orangered"></i>
          {{ $t("home.p85") }}</strong
        >
        <!-- <strong style="margin-left: 50px; cursor: pointer"
          ><i class="fas fa-envelope mr-2" style="color: orangered"></i> Contacta con
          Soporte</strong
        > -->
      </div>
      <hr />
      <ul
        class="ulli d-flex flex-row pt-1 otrBloque"
        style="align-items: center; margin-bottom: 0; width: 100%"
      >
        <router-link
          to="terminos"
          tag="li"
          class="d-flex flex-row mg0"
          style="cursor: pointer; align-items: center; margin-left: 11%; color: #000"
        >
          <i class="fas fa-circle mr-1" style="font-size: 8px"></i> {{ $t("home.p38") }}
          <!-- <li class="ml-5" style="color: #cfcfcf">Políticas de privacidad</li> -->
        </router-link>

        <router-link
          :to="{ name: 'pHuesped' }"
          tag="li"
          class="d-flex flex-row mg0"
          style="cursor: pointer; align-items: center; margin-left: 3%; color: #000"
        >
          <i class="fas fa-circle mr-1" style="font-size: 8px"></i> {{ $t("home.p86") }}
          <!-- <li class="ml-5" style="color: #cfcfcf">Políticas de privacidad</li> -->
        </router-link>

        <router-link
          :to="{ name: 'pHospedaje' }"
          tag="li"
          class="max2 d-flex flex-row"
          style="cursor: pointer; align-items: center; margin-left: 3%; color: #000"
        >
          <i class="fas fa-circle mr-1" style="font-size: 8px"></i> {{ $t("home.p87") }}
          <!-- <li class="ml-5" style="color: #cfcfcf">Políticas de privacidad</li> -->
        </router-link>
      </ul>
    </div>
    <ModalReserva />
  </div>
</template>
<script>
import ModalReserva from "@/views/Landing/modalReserva.vue";
export default {
  mounted() {},
  components: {
    ModalReserva,
  },
  methods: {
    openModal() {
      $("#modalContacto").modal("show");
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .max2 {
    margin-bottom: 53px !important;
  }
  .contact {
    margin-right: 0 !important;
  }
  .ulli {
    padding-left: 0;
  }
  .mg0 {
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  .otrBloque {
    flex-direction: column !important;
    width: 100% !important;
    align-items: normal !important;
  }
  .direccion {
    margin-top: 218px;
  }
  .footerDos {
    flex-direction: column !important;
  }
}

.footerDos {
  height: 130px !important;
  align-items: center;
  color: white;
}
</style>
