import { home } from "./views/home.js";
import { checkin } from "./views/checkin";
import { qrcheckin } from "./views/qrcheckin";
import { selfcheckin } from "./views/selfcheckin";
import { pagewhatsapp } from "./views/pagewhatsapp";
import { legalidad } from "./views/legalidad";
import { police } from "./views/police";
import { upselling } from "./views/upselling";
import { nav } from "./views/nav";
import { integraciones } from './views/integraciones';
import { precios } from './views/precios.js';
import { terminos } from './views/terminos'
import { politicas } from './views/politicasAlojamiento.js'
import { mensajeautomatico } from './views/mensajeautomatico.js'

export const en_i18n = {
    politicas,
    home,
    nav,
    integraciones,
    precios,
    terminos,
    checkin,
    qrcheckin,
    selfcheckin,
    pagewhatsapp,
    legalidad,
    police,
    upselling,
    mensajeautomatico
}